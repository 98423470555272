<template>
  <section id="hero" class="w-full center-flex flex-col py-navbar-mobile md:py-navbar bg-off-white">
    <h3 class="text-2xl md:text-4xl py-2 w-full bg-black text-off-white m-0">Your Trusted Partner in Security</h3>
    <div class="flex flex-col lg:flex-row justify-center items-center lg:items-start  pt-20 lg:pt-32 px-10 gap-20 lg:gap-40">
      <div class="flex flex-col justify-center lg:justify-start items-center lg:items-start w-full lg:w-1/3 text-balance lg:text-left">
        <h1 class="m-0 mb-10">Protecting What Matters Most</h1>
        <p>At ISeeYouSecurity.ca, we take pride in being a Canadian leader in security solutions.</p>
        <p>With a commitment to excellence, we specialize in providing top-tier security services for government agencies, institutions, and organizations across the nation.</p>
        <a href="mailto:iseeyouinc4@iseeyousecurity.ca"><button class="px-4 py-2 border-black hover:border-gold text-black hover:text-gold border-2 text-2xl my-5">Contact Us</button></a>
      </div>
      <img class="w-full md:w-2/3 lg:w-1/3" src="../assets/images/Gordon.png" alt="logo">
    </div>
    <div class="flex flex-col lg:flex-row justify-center items-center lg:items-start pt-32 px-10 gap-20 lg:gap-40">
      <div class="flex flex-col justify-center lg:justify-start items-center lg:items-start w-full lg:w-1/3 text-balance lg:text-left">
        <h1 class="m-0 mb-10">Why Choose us?</h1>
        <p><strong>Proven Expertise:</strong> Our team of highly trained and experienced professionals is dedicated to safeguarding your assets and ensuring the highest levels of security.</p>
        <p><strong>Canadian Excellence:</strong> As a proudly Canadian company, we understand the unique security needs of government entities</p>
        </div>
      <div class="flex flex-col justify-center lg:justify-start items-center lg:items-start w-full lg:w-1/3 text-left">
        <h1 class="m-0 mb-10">Our Services</h1>
        <p>We offer a wide range of security services designed to meet the stringent demands of government security contracts, including:</p>
        <ol class="text-xl">
          <li><strong>Government Facility Security:</strong> Protecting public institutions, government buildings, and sensitive areas with state-of-the-art access control and surveillance systems.</li>
          <li><strong>Personnel Screening:</strong> Ensuring the safety and integrity of your organization through thorough background checks and vetting processes.</li>
          <li><strong>Event Security:</strong> Managing security for high-profile government events, ensuring the safety of attendees and dignitaries.</li>
        </ol>
      </div>
    </div>
    <hr class="border-2 rounded-xl border-gold-center w-2/5 my-28">
    <div class="w-full lg:w-1/2 flex flex-col items-center justify-center px-10">
          <h1 class="m-0">Our Commitment</h1>
          <video controls class="my-10">
            <source src="../assets/videos/icuAbout.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <p class="my-8">We are committed to providing innovative, reliable, and cost-effective security solutions for government agencies. We understand the unique challenges of securing government assets and maintaining the trust of the public. Our focus is on safeguarding your operations and reputation while adhering to the highest standards of professionalism and compliance.</p>
          <div class="flex flex-col lg:flex-row items-center justify-center ">
            <img class="w-full md:w-2/3 lg:w-1/3 rounded-2xl" src="../assets/images/governmentBuildings.png" alt="logo">
            <img class="w-full md:w-2/3 lg:w-1/3" src="../assets/images/icuVerticalLogo.png" alt="logo">
            <img class="w-full md:w-2/3 lg:w-1/3 rounded-2xl" src="../assets/images/securityPhone.png" alt="logo">
          </div>
        </div>
    <hr class="border-2 rounded-xl border-gold-center w-2/5 my-28">
    <div class="w-full lg:w-1/2 flex flex-col items-center justify-center px-10">
          <h1 class="m-0">Get In Touch</h1>
          <p class="my-8">Discover why ISeeYouSecurity is the preferred choice for government security contracts in Canada. Contact us today to discuss your specific security needs and how we can tailor our services to meet them. Your security is our mission.</p>
          <h3>Trust us to watch over what matters most to you.</h3>
          <h3 class="my-5">We see security; We see you</h3>
      <a href="mailto:iseeyouinc4@iseeyousecurity.ca"><button class="px-4 py-2 border-black hover:border-gold text-black hover:text-gold border-2 text-4xl my-10">Contact Us</button></a>
        </div>
   </section>
 </template>

<script>

export default {
  name: 'HomeView',
};
</script>


<style scoped>
.center-flex{
  @apply flex justify-center items-center
}

strong{
  @apply font-extrabold
}

li{
  @apply my-5 lg:my-3
}
</style>