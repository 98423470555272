<template>
  <nav id="navbar"
       class="flex items-center justify-center fixed top-0 left-0 w-full z-10 bg-off-white py-3 px-8 border-gold border-b-8">
         <router-link to="/" class="flex flex-row items-center justify-center">
          <img class="h-20 md:h-40 w-auto" src="../assets/images/icuLogoFull.png" alt="logo">
        </router-link>
    </nav>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>