<script>
import { RouterView } from 'vue-router';
import FooterSection from '@/sections/FooterSection.vue';
import NavBar from './sections/NavBar.vue';


export default {
  components: {
    FooterSection,
    NavBar,
    RouterView,
  },
};
</script>

<template>
  <div class="bg-white h-screen">
    <NavBar/>
    <RouterView/>
    <FooterSection/>
  </div>
</template>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>